<template>
  <div>
    <div class="npr-camera__header pt-3 pb-2">
      <span>{{ camera.name }}</span>
      <div
        v-if="camera.webrtc_supported"
        class="npr-camera__channel"
      >
        <BSelectLabel
          :disabled="!playing"
          :label="$t('intercom.video_channel')"
          :items="[1, 2]"
          :value="videoChannel"
          @select="onSelectChannel"
        ></BSelectLabel>
      </div>
      <div
        :class="camera.status == 'online' ? 'text-success' : 'text-danger'"
        v-if="camera.status"
      >
        {{ $t(`camera.${camera.status}`) }}
      </div>
    </div>
    <div
      v-if="camera.webrtc_supported"
      class="img img-fluid d-block w-100 h-100 camera-img"
    >
      <CameraPlayer
        :background="camera.live_snapshot_url"
        :deviceId="camera.id"
        :video-channel="videoChannel"
        @play="onPlay"
      />
    </div>
    <template v-else>
      <div
        class="d-flex justify-content-center align-items-center h-100 w-100"
      >
        <img
          v-if="camera.live_snapshot_url"
          class="h-auto w-auto mw-100 mh-100 rounded-lg"
          :src="camera.live_snapshot_url"
        />

        <div v-else class="img-placeholder w-100 camera-img" />
      </div>
    </template>
  </div>
</template>
<script>
import CameraPlayer from '@slim/manage_components/npr/CameraPlayer.vue';

export default {
  components: {
    CameraPlayer,
  },
  props: {
    camera: {
      type: Object,
    },
  },
  data() {
    return {
      videoChannel: 2,
      playing: false,
    };
  },
  methods: {
    onSelectChannel(value) {
      this.videoChannel = value;
    },
    onPlay(value) {
      this.playing = value;
    },
  },
};
</script>
<style>
.camera-img {
  object-fit: cover;
}
.npr-camera__header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
.npr-camera__channel {
  margin-left: auto;
  margin-right: 2rem;
  min-width: 12rem;
}
</style>
