<template>
  <b-modal ref="errorUuid" :title="$t('devices.modal.create')" no-stacking>
    <div class="modal-body-row with-cols pt-30">
      <b-col cols="12" class="mb-3">
        <b-input-label
          :label="$t('devices.modal.model')"
          v-model="camera.name"
          disabled
          class="mb-0"
        />
        <span
          class="text-danger small"
          v-for="error in errors.cameras_model_id"
          :key="error"
        >
          {{ error }}
        </span>
      </b-col>
      <b-col cols="12" class="mb-3">
        <b-input-label
          label="UUID"
          v-model="camera.uuid"
          disabled
          class="mb-0"
        />
        <span
          class="text-danger small"
          v-for="error in errors.uuid"
          :key="error"
        >
          {{ error }}
        </span>
      </b-col>
      <b-col cols="12" class="mb-3">
        <b-input-label
          :label="$t('devices.modal.serial')"
          v-model="camera.serial"
          disabled
          class="mb-0"
        />
        <span
          class="text-danger small"
          v-for="error in errors.serial"
          :key="error"
        >
          {{ error }}
        </span>
      </b-col>
    </div>
    <template #modal-footer>
      <div
        class="d-flex align-items-center justify-content-between flex-grow-1"
      >
        <b-button
          @click="openUuidSelect"
          size="sm"
          variant="outline-primary"
          class="fast-modal__btn w-auto h-auto"
        >
          {{ $t('devices.modal.back') }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="fast-modal__btn w-auto h-auto"
          disabled
        >
          {{ $t('devices.modal.next') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { EVENTS } from '@/consts';
import EventBus from '@/packs/EventBus';

export default {
  name: 'ErrorUuidModal',
  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      errors: [],
    };
  },
  computed: {
    ...mapState('camera', ['camera']),
    ...mapGetters('camera', ['getCamera']),

    camera: {
      get() {
        return this.getCamera;
      },
      set(value) {
        this.setCamera(value);
      },
    },
  },
  methods: {
    ...mapMutations('camera', ['setCamera']),

    show(errors) {
      this.$refs.errorUuid.show();
      this.errors = errors;
    },
    hide() {
      this.$refs.errorUuid.hide();
    },
    openUuidSelect() {
      this.hide();
      EventBus.$emit(EVENTS.camera.fast.modal.show);
    },
  },
};
</script>

<style></style>
