<template>
  <div class="d-grid grid-template-columns grid-template-columns-2 gap-4 mb-4">
    <Camera
      class="portlet pt-0 mb-0 d-flex flex-column flex-grow-1"
      v-for="camera in camerasSnapshots"
      :key="camera.id"
      :camera="camera"
    />
  </div>
</template>
<script>
import EventBus from '@/packs/EventBus';
import { request3 } from '@/api/request';
import { API_URLS } from '@/consts';
import Camera from '@slim/manage_components/npr/Camera.vue';

export default {
  name: 'NprCameras',
  components: {
    Camera,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      camerasSnapshots: [],
    };
  },
  mounted() {
    EventBus.$on('NprEvents::update', () => {
      this.updateSnapshots();
    });
  },
  created() {
    this.updateSnapshots();
  },
  computed: {
    uuidCameras() {
      return this.value.map((camera) => {
        return camera.id;
      });
    },
  },
  methods: {
    async updateSnapshots() {
      if (this.uuidCameras.length === 0) return;
      this.camerasSnapshots = await request3
        .get(API_URLS.backendManage.cctv.cameras.devices.fetch(this.clientId), {
          params: {
            q: {
              id_in: this.uuidCameras,
            },
          },
        })
        .then((res) => res.data.cameras);
    },
  },
};
</script>
<style>
</style>
