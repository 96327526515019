<template>
  <div>
    <b-form-group
      :id="getGroupId"
      :label="label"
      :invalid-feedback="invalidFeedback"
      :label-class="
        labelIsActive || value || value === 0 || placeholder || autofilled
          ? 'float-label-active'
          : 'float-label-inactive'
      "
      :class="getFormGroupClasses"
      :description="description"
      :state="state"
      :data-title="label"
      class="b-input-label"
    >
      <div
        v-if="security && value"
        class="float-security"
        :class="show ? 'icon-local-eye-closed' : 'icon-local-eye'"
        @click="toggleShowingPassword"
      ></div>
      <div v-if="isShowIconSlot" class="icon-slot">
        <slot name="icon"></slot>
      </div>
      <div
        class="icon-block pointer"
        v-if="icon"
        @click="
          () => {
            onIconClick();
            handler && handler();
          }
        "
      >
        <i :class="icon" />
      </div>
      <div v-if="withMask || maskType" class="warning-block">
        <span class="warning-text">{{ warningMessage }}</span>
        <i class="icon-warning"></i>
      </div>
      <span v-if="withMask || maskType" class="wrap">
        <span aria-hidden="true">
          <i>{{ formattedValue }}</i>
          {{ remainingMask }}
        </span>
        <b-form-checkbox
          v-if="!maskType"
          v-model="switchMaskChecked"
          name="check-button"
          switch
          size="lg"
        >
          {{ switchMaskChecked ? 'Серийный номер' : 'uuid' }}
        </b-form-checkbox>
        <b-form-input
          ref="input"
          :class="[
            'float-input',
            'masked-input',
            {
              'active-validation-to-disable': this.activeValidationToDisable,
              'is-invalid': isInvalid,
            },
          ]"
          :value="value"
          :id="id"
          :type="getInputType"
          :name="name"
          :disabled="disabled"
          :placeholder="placeholder"
          :state="state"
          v-bind="$attrs"
          @keyup="$emit('keyup')"
          @keydown="handleDown"
          @focus="onInputFocus($event)"
          @blur="onInputBlur($event)"
          @input="input($event)"
          @input.native="emmitEvent($event, 'inputNative')"
          @change="change"
          @update="update"
          :autocomplete="autocomplete"
          :autofocus="autofocus"
          :pattern="pattern"
          @animationstart="checkAnimation"
        ></b-form-input>
      </span>
      <b-form-input
        v-else
        ref="input"
        :class="[
          'float-input',
          {
            'active-validation-to-disable': this.activeValidationToDisable,
            'is-invalid': isInvalid,
          },
        ]"
        :value="value"
        :id="id"
        :type="getInputType"
        :name="name"
        :disabled="disabled"
        :placeholder="placeholder"
        :state="state"
        v-bind="$attrs"
        @keyup="$emit('keyup')"
        @focus="onInputFocus($event)"
        @blur="onInputBlur($event)"
        @input="input($event)"
        @input.native="emmitEvent($event, 'inputNative')"
        @change="change"
        @update="update"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        :pattern="pattern"
        @animationstart="checkAnimation"
      ></b-form-input>
      <div v-if="withCustomValidation" class="custom-validation">
        <span class="error-text">{{ customValidationText }}</span>
        <i class="icon-error"></i>
      </div>
      <slot></slot>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';
import IMask from 'imask';

export default {
  name: 'BInputLabel',
  components: { BFormGroup, BFormInput },
  props: {
    value: {},
    description: { type: String },
    id: String,
    label: String,
    name: String,
    placeholder: String,
    autocomplete: String,
    autofocus: { type: Boolean, default: false },
    security: Boolean,
    disabled: Boolean,
    withCustomValidation: Boolean,
    customValidationText: String,
    type: String,
    icon: {
      type: String,
      default: '',
    },
    handler: Function,
    activeValidationToDisable: Boolean,
    state: {
      type: [Boolean || undefined],
      default: undefined,
    },
    focusHandler: Function,
    invalidFeedback: String,
    pattern: String,
    inputGroupClasses: Object,
    blockValue: String,
    isInvalid: {
      type: Boolean,
      default: false,
    },
    selectedResult: Object,
    maskType: String,
    clearInput: Boolean,
    withMask: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      labelIsActive: false,
      show: true,
      autofilled: false,
      unmaskedValue: '',
      warningMessage: '',
      inputElement: null,
      formattedValue: '',
      remainingMask: '',
      lastUserInput: '',
      switchMaskChecked: true,
      serialMask: {
        mask: '****************',
        type: 'serial',
        placeholderChar: 'x',
        definitions: {
          '*': /[0-9]/,
        },
      },
      uuidMask: {
        mask: '********-****-****-****-************',
        type: 'uuid',
        placeholderChar: 'x',
        definitions: {
          '*': /[A-Za-z0-9]/,
        },
      },
      ipMask: {
        mask: '*[**].*[**].*[**].*[**]',
        type: 'ip',
        placeholderChar: 'x',
        definitions: {
          '*': /[0-9]/,
        },
      },
      macMask: {
        mask: '**:**:**:**:**:**',
        type: 'mac',
        placeholderChar: 'x',
        definitions: {
          '*': /^[0-9A-Fa-f]+$/,
        },
      },
      phoneMask: {
        mask: '7(000)000-00-00',
        type: 'phone',
        placeholderChar: 'x',
      },
      maskValueStorage: {},
    };
  },
  mounted() {
    if (this.withMask || this.maskType) {
      this.$nextTick(() => {
        this.inputElement = this.$refs.input.$el;
        this.initMask();
        this.inputElement.addEventListener('cut', this.cutEventListner);
      });
    }
  },
  computed: {
    getInputType() {
      if (this.type) return this.type;
      return this.show && this.security ? 'password' : 'text';
    },
    getGroupId() {
      return this.id ? `group-${this.id}` : '';
    },
    getFormGroupClasses() {
      return {
        'input-with-label': true,
        ...this.inputGroupClasses,
      };
    },
    isShowIconSlot() {
      return !!this.$slots.icon;
    },
  },
  methods: {
    initMask() {
      if (this.withMask || this.maskType) {
        this.mask = IMask(this.inputElement, {
          mask: [
            this.serialMask,
            this.uuidMask,
            this.ipMask,
            this.macMask,
            this.phoneMask,
          ],
          dispatch: (_, dynamicMasked) => {
            if (!!this.maskType) {
              return dynamicMasked.compiledMasks.find(
                (m) => m.type === this.maskType,
              );
            }

            return dynamicMasked.compiledMasks[0];
          },
        });
      }
    },
    setWrapMaskValues(value) {
      const cutLength = value?.length || this.mask?._value.length;
      this.formattedValue = value || this.mask?.value;
      this.remainingMask = this.mask?.masked.currentMask.mask
        .replace(/\[.*?\]/g, '')
        .substr(cutLength);
    },
    setCursorPosition(value) {
      this.lastUserInput = value;
      if (this.lastUserInput) {
        const cursorPosition = this.mask.cursorPos;
        this.mask.updateValue();
        const unmaskedLength = this.mask._rawInputValue.length;

        setTimeout(() => {
          if (unmaskedLength > cursorPosition) {
            this.inputElement.selectionStart = cursorPosition;
            this.inputElement.selectionEnd = cursorPosition;
          } else {
            const unmaskedLength = this.mask._rawInputValue.length;
            const adjustedCursorPos = Math.max(
              cursorPosition,
              unmaskedLength,
              value.length,
            );

            this.inputElement.selectionStart = adjustedCursorPos;
            this.inputElement.selectionEnd = adjustedCursorPos;
          }
        }, 0);
      }
    },
    activatedLabel() {
      this.labelIsActive = true;
    },
    deactivateLabel() {
      this.labelIsActive = false;
    },
    change(val) {
      this.emmitEvent(val, 'change');
    },
    input(value) {
      if (this.withMask || this.maskType) {
        this.mask.updateValue();
        this.setWrapMaskValues();
        this.maskValueStorage[this.mask.masked.currentMask.type] = value;
        if (this.formattedValue) {
          this.emitMaskValue();
        }
        this.setCursorPosition(value);
      } else {
        this.emmitEvent(value, 'input');
      }
    },
    handleDown(event) {
      const maskIsFilled =
        !!this.mask && this.mask.masked.isComplete && this.mask.masked.isFilled;
      const keyIsValue = ![
        'Backspace',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
      ].includes(event.key);

      if (maskIsFilled && keyIsValue) {
        this.warningMessage = this.$t('devices.errors.max_length_reached');
      } else {
        this.warningMessage = '';
      }
    },
    update(val) {
      this.emmitEvent(val, 'update');
    },
    emmitEvent(val, event) {
      if (this.blockValue) {
        if (val.indexOf(this.blockValue) !== 0) {
          this.$emit(event, this.blockValue);
          this.$refs.input.$data.localValue = this.blockValue;
          return;
        }
      }
      if (this.type === 'number') {
        if (val === '') {
          this.$emit(event, null);
        } else {
          this.$emit(event, Number(val));
        }
      } else {
        this.$emit(event, val);
      }
    },
    changeValue(val) {
      if (this.blockValue) {
        if (!(val.indexOf(this.blockValue) < -1)) {
          this.$emit('input', this.blockValue);
          return;
        }
      }
      if (this.type === 'number') {
        if (val === '') {
          this.$emit('input', val);
        } else {
          this.$emit('input', Number(val));
        }
      } else {
        this.$emit('input', val);
      }
    },
    toggleShowingPassword() {
      this.show = !this.show;
    },
    checkAnimation(event) {
      if (event.animationName === 'onAutoFillStart') this.autofilled = true;
      else if (event.animationName === 'onAutoFillCancel')
        this.autofilled = false;
    },
    focus() {
      this.$refs.input.focus();
      this.setWrapMaskValues();

      if (this.withMask || this.maskType) {
        this.mask.updateValue();
      }
    },
    blur() {
      this.remainingMask = '';
      this.$refs.input.blur();
    },
    onInputFocus(event) {
      if (this.focusHandler) this.focusHandler();
      this.activatedLabel();
      this.$emit('focus', event);
      if (this.withMask || this.maskType) {
        this.mask.updateValue();
      }
    },
    onInputBlur() {
      this.remainingMask = '';
      this.deactivateLabel();
      this.$emit('blur', this.value);
    },
    onIconClick() {
      this.$emit('click-icon');
    },
    emitMaskValue(value) {
      this.emmitEvent(value || this.formattedValue, 'input');
      this.$emit('unmaskedvalue', this.formattedValue);
      this.$emit('focus');
    },
    clearValues() {
      if (this.mask && this.mask.value) {
        this.mask.value = '';
        this.warningMessage = '';
        this.remainingMask = '';
        this.formattedValue = '';
        this.unmaskedValue = '';
        this.$emit('input', '');
      }
    },
    cutEventListner(event) {
      event.preventDefault();
      event.clipboardData.setData('text/plain', this.formattedValue);
      if (this.mask && this.mask.value) {
        this.clearValues();
      }
    },
  },
  beforeDestroy() {
    if (this.mask) {
      this.mask.destroy();
      this.inputElement.removeEventListener('cut', this.cutEventListner);
    }
  },
  watch: {
    selectedResult: {
      deep: true,
      handler(value) {
        if ((this.withMask || this.maskType) && value.name) {
          this.unmaskedValue = this.value;
        }
      },
    },
    value(value) {
      if (!value) {
        this.clearValues();
      }
    },
    switchMaskChecked(switchMaskChecked) {
      this.clearValues();
      if (switchMaskChecked) {
        this.mask.masked.updateOptions({
          mask: [this.serialMask],
        });
      } else {
        this.mask.masked.updateOptions({
          mask: [this.uuidMask],
        });
      }
      this.mask.updateValue();
      const storedMaskTypeValue =
        this.maskValueStorage[this.mask.masked.currentMask.type];
      this.focus();
      if (storedMaskTypeValue) {
        this.$emit('input', storedMaskTypeValue);
        this.setWrapMaskValues(storedMaskTypeValue);
        this.setCursorPosition(storedMaskTypeValue);
      }
    },
    clearInput() {
      this.maskValueStorage[this.mask.masked.currentMask.type] = '';
      this.$emit('resetClearInputFlag');
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/styles/components/variables';
.float-security {
  font-size: $font-size-20px;
  position: absolute;
  right: 50px;
  margin-top: 15px;
}

.icon-slot {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  .dropdown {
    height: 50px;

    ul {
      left: -15px !important;
      top: 40px !important;

      &,
      * {
        font-family: $font-family-sans-serif;
      }
    }
  }
  &:hover {
    * {
      color: $blue;
      cursor: pointer;
    }
  }
}

.icon-block {
  position: absolute;
  cursor: pointer !important;
  text-align: center;
  right: 15px;
  height: 30px;

  &:hover {
    i {
      color: $blue;
    }
  }
  i {
    color: #bfc5d1;
    font-size: 20px;
    line-height: 50px;
    cursor: pointer;
  }
}

.b-input-label > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.wrap {
  width: 100%;
  position: relative;
  line-height: 1;
  span {
    position: absolute;
    left: 15px;
    top: 27px;
    pointer-events: none;
    opacity: 0.5;
    i {
      color: transparent;
      opacity: 0;
      visibility: hidden;
    }
  }
  .custom-control {
    position: absolute;
    height: fit-content;
    top: -25px;
    .custom-control-label {
      height: fit-content;
    }
  }
}

input.masked-input,
.wrap span {
  padding-right: 10px;
  background-color: transparent;
  text-transform: uppercase;
}

.warning-block {
  position: absolute;
  text-align: right;
  top: -25px;
  .warning-text {
    color: #03a9f4;
  }
}

.input-with-label legend {
  border: none;
  outline: none;
}
</style>
