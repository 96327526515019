<template>
  <div class="devices-autocomplete">
    <BDropdownInput
      v-model="selected_id"
      :description="description"
      :disabled="disabled"
      :label="placeholder"
      :handler="fetch"
      :selected="deviceData"
      :state="state"
      :showLoading="showLoading"
      :variantSpinner="variantSpinner"
      :debouncedTts="debouncedTts"
      :formattedResult="formattedResult"
      compare-property="uuid"
      display-property="name"
      @clear="onClear"
      @focus="onFocus"
      @select="onSelect"
      @select:item="onSelectItem"
      :popoverText="popoverText"
      :popoverHint="popoverHint"
      :hintId="hintId"
      :minWordLength="minWordLength"
      :withMask="withMask"
    />
  </div>
</template>

<script>
import BDropdownInput from '@/components/base/BDropdownInput.vue';
import { request3 } from '@/api/request';

export default {
  name: 'DevicesAutocomplete',
  components: {
    BDropdownInput,
  },
  props: {
    saveFormId: String,
    model: String,
    name: String,
    deviceData: Object,
    description: {
      type: String,
    },
    clientId: [String, Number],
    placeholder: String,
    value: {
      type: [String, Number],
    },
    state: {},
    debouncedTts: {
      type: Number,
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    additionalQueryParams: {
      type: Object,
      default() {
        return {};
      },
    },
    geoUnitId: {
      type: Number,
      default: null,
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    variantSpinner: {
      type: String,
      default: 'primary',
    },
    minWordLength: {
      type: Number,
      default: 4,
    },
    popoverText: {
      type: String,
      default: 'i',
    },
    popoverHint: {
      type: String,
      default: null,
    },
    hintId: {
      type: String,
      default: null,
    },
    placementPopover: {
      type: String,
      default: 'bottom',
    },
    typeDevice: {
      type: String,
      default: 'intercom',
    },
    url: {
      type: Object,
      required: true,
    },
    withMask: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected_id: null,
    };
  },
  computed: {
    formattedResult() {
      return {
        top: [{ title: 'name', formatter: (value) => value }],
        bottom: [
          {
            title: 'uuid',
            formatter: (val, item) => item.uuid,
          },
        ],
      };
    },
  },
  mounted() {
    if (this.deviceData instanceof Object) {
      this.word = this.deviceData.name;
      this.selected_id = this.deviceData.uuid;
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('input', value);
      this.$emit('select', value);
      this.selected_id = value;
      this.saveForm();
    },
    onSelectItem(item) {
      this.$emit('select:item', item);
    },
    saveForm() {
      if (_.isUndefined(this.saveFormId)) {
        return;
      }

      const vm = this;
      setTimeout(function () {
        $(`#${vm.saveFormId}`).submit();
      }, 100);
    },
    fetch(val) {
      this.isLoading = true;
      if (this.typeDevice == 'intercom') {
        return this.getIntercoms(val);
      }
      if (this.typeDevice == 'camera') {
        return this.getCameras(val);
      }
    },
    getCameras(val) {
      return request3
        .get(this.url.uuidOrSerial(this.clientId), {
          params: {
            uuid_or_serial: encodeURIComponent(val),
            ...this.additionalQueryParams,
          },
        })
        .then((res) => res.data)
        .finally(() => {
          this.isLoading = false;
        });
    },
    getIntercoms(val) {
      return request3
        .get(this.url.fetch(this.clientId), {
          params: {
            intercom: {
              uuid_or_serial: encodeURIComponent(val),
              ...this.additionalQueryParams,
            },
          },
        })
        .then((res) => res.data)
        .finally(() => {
          this.isLoading = false;
        });
    },
    onClear() {
      this.$emit('clear');
    },
    onFocus() {
      this.$emit('focus');
    },
  },
};
</script>

<style lang="scss" scoped>
#dropdown {
  padding-top: 20px;
  .icon-container {
    top: 33px;
  }
}
</style>

